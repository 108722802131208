// Alert Types
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth Types
// user
export const START_LOGIN = 'START_LOGIN';
export const START_REGISTER = 'START_REGISTER';
export const START_LOGOUT = 'START_LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
// collection
export const EDIT_USER_COLLECTION_REF_DETAILS = 'EDIT_USER_COLLECTION_DETAILS';
export const ADD_BOURBON_TO_USER_COLLECTION_REF =
	'ADD_BOURBON_TO_USER_COLLECTION_REF';
export const DELETE_BOURBON_FROM_USER_COLLECTION_REF =
	'DELETE_BOURBON_FROM_USER_COLLECTION_REF';
export const CREATE_USER_COLLECTION_REF = 'CREATE_USER_COLLECTION_REF';
export const DELETE_USER_COLLECTION_REF = 'DELETE_USER_COLLECTION_REF';
// wishlist
export const EDIT_USER_WISHLIST_REF_DETAILS = 'EDIT_USER_WISHLIST_DETAILS';
export const ADD_BOURBON_TO_USER_WISHLIST_REF =
	'ADD_BOURBON_TO_USER_WISHLIST_REF';
export const DELETE_BOURBON_FROM_USER_WISHLIST_REF =
	'DELETE_BOURBON_FROM_USER_WISHLIST_REF';
export const CREATE_USER_WISHLIST_REF = 'CREATE_USER_WISHLIST_REF';
export const DELETE_USER_WISHLIST_REF = 'DELETE_USER_WISHLIST_REF';
export const UPDATE_USER_WISHLISTS = 'UPDATE_USER_WISHLISTS';
// review
export const CREATE_USER_REVIEW_REF = 'CREATE_USER_REVIEW_REF';
export const EDIT_USER_REVIEW_REF = 'EDIT_USER_REVIEW_REF';
export const DELETE_USER_REVIEW_REF = 'DELETE_USER_REVIEW_REF';

// Bourbon Types
export const START_PAGINATED_FETCH = 'START_PAGINATED_FETCH';
export const GET_PAGINATED_SUCCESS = 'GET_PAGINATED_SUCCESS';
export const GET_PAGINATED_FAILURE = 'GET_PAGINATED_FAILURE';
export const GET_BOURBON_SUCCESS = 'GET_BOURBON_SUCCESS';
export const GET_BOURBON_FAILURE = 'GET_BOURBON_FAILURE';
export const CLEANUP_BOURBONS = 'CLEANUP_BOURBONS';
export const CLEANUP_BOURBON = 'CLEANUP_BOURBON';

// Review Types
export const GET_BOURBON_REVIEWS_SUCCESS = 'GET_BOURBON_REVIEWS_SUCCESS';
export const GET_BOURBON_REVIEWS_FAILURE = 'GET_BOURBON_REVIEWS_FAILURE';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAILURE = 'CREATE_REVIEW_FAILURE';
export const EDIT_REVIEW_SUCCESS = 'EDIT_REVIEW_SUCCESS';
export const EDIT_REVIEW_FAILURE = 'EDIT_REVIEW_FAILURE';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';
export const GET_USER_REVIEWS_SUCCESS = 'GET_USER_REVIEWS_SUCCESS';
export const GET_USER_REVIEWS_FAILURE = 'GET_USER_REVIEWS_FAILURE';
export const SET_DASH_REVIEW = 'SET_DASH_REVIEW';
export const CLEANUP_REVIEWS = 'CLEANUP_REVIEWS';

// Collection Types
export const GET_USER_COLLECTIONS_SUCCESS = 'GET_USER_COLLECTIONS_SUCCESS';
export const GET_USER_COLLECTIONS_FAILURE = 'GET_USER_COLLECTIONS_FAILURE';
export const GET_USER_COLLECTION_SUCCESS = 'GET_USER_COLLECTION_SUCCESS';
export const GET_USER_COLLECTION_FAILURE = 'GET_USER_COLLECTION_FAILURE';
export const CLEANUP_QUICKLOOK = 'CLEANUP_QUICKLOOK';
export const CLEANUP_COLLECTION = 'CLEANUP_COLLECTION';
export const SET_COLLECTION_QUICKLOOK = 'SET_COLLECTION_QUICKLOOK';
export const EDIT_COLLECTION_SUCCESS = 'EDIT_COLLECTION_SUCCESS';
export const EDIT_COLLECTION_FAILURE = 'EDIT_COLLECTION_FAILURE';
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_FAILURE = 'CREATE_COLLECTION_FAILURE';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_FAILURE = 'DELETE_COLLECTION_FAILURE';
export const DELETE_BOURBON_FROM_COLLECTION_SUCCESS =
	'DELETE_BOURBON_FROM_COLLECTION_SUCCESS';
export const DELETE_BOURBON_FROM_COLLECTION_FAILURE =
	'DELETE_BOURBON_FROM_COLLECTION_FAILURE';

// Wishlist Types
export const GET_USER_WISHLISTS_SUCCESS = 'GET_USER_WISHLISTS_SUCCESS';
export const GET_USER_WISHLISTS_FAILURE = 'GET_USER_WISHLISTS_FAILURE';
export const GET_USER_WISHLIST_SUCCESS = 'GET_USER_WISHLIST_SUCCESS';
export const GET_USER_WISHLIST_FAILURE = 'GET_USER_WISHLIST_FAILURE';
export const CLEANUP_WISHLIST_QUICKLOOK = 'CLEANUP_WISHLIST_QUICKLOOK';
export const CLEANUP_WISHLIST = 'CLEANUP_WISHLIST';
export const SET_WISHLIST_QUICKLOOK = 'SET_WISHLIST_QUICKLOOK';
export const EDIT_WISHLIST_SUCCESS = 'EDIT_WISHLIST_SUCCESS';
export const EDIT_WISHLIST_FAILURE = 'EDIT_WISHLIST_FAILURE';
export const CREATE_WISHLIST_SUCCESS = 'CREATE_WISHLIST_SUCCESS';
export const CREATE_WISHLIST_FAILURE = 'CREATE_WISHLIST_FAILURE';
export const DELETE_WISHLIST_SUCCESS = 'DELETE_WISHLIST_SUCCESS';
export const DELETE_WISHLIST_FAILURE = 'DELETE_WISHLIST_FAILURE';
export const DELETE_BOURBON_FROM_WISHLIST_SUCCESS =
	'DELETE_BOURBON_FROM_WISHLIST_SUCCESS';
export const DELETE_BOURBON_FROM_WISHLIST_FAILURE =
	'DELETE_BOURBON_FROM_WISHLIST_FAILURE';
